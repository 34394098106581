import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'

import ProjectRoll from '../components/ProjectRoll'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'

export const ProjectPostTemplate = ({post, helmet, contentComponent}) => {
  const PostContent = contentComponent || post.html

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            {/* eslint-disable-next-line */}
            <a href="javascript:history.back()">&larr; Back</a>
            <h1 className="post-title">
            {post.frontmatter.title}
            </h1>
            {post.frontmatter.tags && post.frontmatter.tags.length ? (
              <div>
                <ul className="taglist">
                  {post.frontmatter.tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <PostContent content={post.html} />
            <br/>
            {post.frontmatter.video ? <a href={post.frontmatter.video}>video</a> : null}
            <br/>
            <h1>Edbert's Projects</h1>
            <br/>
            <ProjectRoll current={post.fields.slug} />
          </div>
        </div>
      </div>
    </section>
  )
}

ProjectPostTemplate.propTypes = {
  contentComponent: PropTypes.func,
  post: PropTypes.object,
  helmet: PropTypes.object,
}

const ProjectPost = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <ProjectPostTemplate
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Meet Edbert">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        post={post}
      />
    </Layout>
  )
}

ProjectPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ProjectPost

export const pageQuery = graphql`
  query ProjectPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        tags
        templateKey
        metaDesc
        metaImg {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hoverImg {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date
        showVideo
        video
      }
    }
  }
`
